import React, { useState, useEffect} from 'react';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import axios from 'axios';
import './Myaccount.css';
const PaymentMethods = () => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    useEffect(() => {
        // Fetch payment methods from the API
        const fetchPaymentMethods = async () => {
            try {
                const response = await axios.post('https://www.alexer.dev/api/stripe/listpm', {
                    token: Cookies.get('jwtToken'),
                });

                setPaymentMethods(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching payment methods:', error);
                setIsLoading(false);
            }
        };

        fetchPaymentMethods();
    }, []);
    const handlePaymentMethodChange = (method) => {
        setSelectedPaymentMethod(method);
    };

    const handlePaymentSubmit = () => {
        if (selectedPaymentMethod) {
            console.log('Selected Payment Method ID:', selectedPaymentMethod.id);
        } else {
            console.log('Please select a payment method.');
        }
    }
    return (
        <div>
            <h2>Your Payment Methods</h2>
            {isLoading ? (
                <p>Loading payment methods...</p>
            ) : (
                <form>
                    <ul>
                        {paymentMethods.map((method) => (
                            <li key={method.id}>
                                <label>
                                    <input
                                        type="radio"
                                        name="paymentMethod"
                                        value={method.id}
                                        checked={selectedPaymentMethod === method}
                                        onChange={() => handlePaymentMethodChange(method)}
                                    />
                                    <strong>{method.brand}</strong> ending in {method.last4}
                                </label>
                            </li>
                        ))}
                    </ul>
                    <button className="acc-button" type="button" onClick={handlePaymentSubmit}>
                        Log Selected Payment Method ID
                    </button>
                </form>
            )}
        </div>
    );
};

const MyAccount = () => {
    const token = Cookies.get('jwtToken');
    let userData = null;

    const [newPassword, setNewPassword] = useState('');
    const [newName, setNewName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [changePasswordMessage, setChangePasswordMessage] = useState('');
    const [changeNameMessage, setChangeNameMessage] = useState('');
    const [changeEmailMessage, setChangeEmailMessage] = useState('');
    const [isChangeNameDialogOpen, setChangeNameDialogOpen] = useState(false);
    const [isChangeEmailDialogOpen, setChangeEmailDialogOpen] = useState(false);

    const handleOpenChangeNameDialog = () => {
        setChangeEmailDialogOpen(false);

        setChangeNameDialogOpen(true);
    };

    const handleCloseChangeNameDialog = () => {
        setChangeNameDialogOpen(false);
    };

    const handleOpenChangeEmailDialog = () => {
        setChangeNameDialogOpen(false);

        setChangeEmailDialogOpen(true  );
    };

    const handleCloseChangeEmailDialog = () => {
        setChangeEmailDialogOpen(false);
    };

    const handleOpenDialog = () => {

        handleOpenChangeNameDialog();
        handleOpenChangeEmailDialog();
    };
    const handleCloseDialog = () => {
        handleCloseChangeNameDialog();
        handleCloseChangeEmailDialog();
    };
    const handleChangeName = async () => {
        try {
            const response = await axios.post('https://alexer.dev/api/user/update', {
                type: 'name',
                value: newName,
                token: token,
            });

            setChangeNameMessage(response.data.message);

            // If the name change was successful, update the JWT token in the cookie
            if (response.data.success) {
                const newToken = response.data.newToken;
                Cookies.set('jwtToken', newToken);
            }

            handleCloseDialog();
        } catch (error) {
            setChangeNameMessage(error.response.data.message);
            console.error(error);
        }
    };

    const handleChangeEmail = async () => {
        try {
            const response = await axios.post('https://alexer.dev/api/user/update', {
                type: 'email',
                value: newEmail,
                token: token,
            });

            setChangeEmailMessage(response.data.message);

            // If the email change was successful, update the JWT token in the cookie
            if (response.data.success) {
                const newToken = response.data.newToken;
                Cookies.set('jwtToken', newToken);
            }

            handleCloseDialog();
        } catch (error) {
            setChangeEmailMessage(error.response.data.message);
            console.error(error);
        }
    };
    const handleChangePassword = async () => {
        try {
            const response = await axios.post('https://alexer.dev/api/user/update', {
                type: 'password',
                value: newPassword,
                token: token,
            });

            setChangePasswordMessage(response.data.message);
        } catch (error) {
            setChangePasswordMessage(error.response.data.message);
            console.error(error);
        }
    };
    if (!token) {
        window.location.replace('/login');
    } else {
        try {
            const tokenData = jwtDecode(token);
            const { name, email } = tokenData;
            userData = {
                name,
                email,
            };
        } catch (error) {
            console.error('Error decoding JWT token:', error);
        }
    }

    const handleSignOut = () => {
        Cookies.remove('jwtToken');
        window.location.replace('/login');
    };

    return (
        <div className="container">
            <h2>My Account</h2>
            {userData ? (
                <div className="user-info">
                    <p>Name: {userData.name}</p>
                    <p>Email: {userData.email}</p>
                    <button className="acc-button" onClick={handleOpenChangeNameDialog}>Change Name</button>
                    <button className="acc-button" onClick={handleOpenChangeEmailDialog}>Change Email</button>
                    <div>
                        <label>Change Password:</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <button className="acc-button" onClick={handleChangePassword}>Change</button>
                        {changePasswordMessage && <p>{changePasswordMessage}</p>}
                    </div>
                    <button className="acc-button" onClick={handleSignOut}>Sign Out</button>

                    {isChangeNameDialogOpen  && (
                        <div className="change-dialog">
                            <label>New Name:</label>
                            <input
                                type="text"
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                            />
                            <button className="acc-button" onClick={handleChangeName}>Save</button>
                            {changeNameMessage && <p>{changeNameMessage}</p>}
                        </div>)}
                    {isChangeEmailDialogOpen  && (
                        <div className="change-dialog">
                            <label>New Email:</label>
                            <input
                                type="email"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                            />
                            <button className="acc-button" onClick={handleChangeEmail}>Save</button>
                            {changeEmailMessage && <p>{changeEmailMessage}</p>}
                        </div>
                    )}
                </div>
            ) : null}



            <PaymentMethods/>
        </div>
    );
};

export default MyAccount;
