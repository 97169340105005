import React from "react";
import "./Header.scss";
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import {Link,useLocation } from 'react-router-dom';
const Header = () => {
    let userName = '';
    const jwtToken = Cookies.get('jwtToken');
    if (jwtToken) {
        try {
            const decodedToken = jwtDecode(jwtToken);
            userName = decodedToken.name; // Replace 'name' with the actual key in your payload
        } catch (error) {
            console.error('Error decoding JWT token:', error);
        }
    }


        const location = useLocation(); // Get the current URL

        return (
            <div className="header">
                <h1>Random Coding Project</h1>
                {userName && !["/login", "/myaccount"].includes(location.pathname) ? (
                    <span>
          {userName} <br /> <Link to="/myaccount" className="hlink">My Account</Link>
        </span>
                ) : (
                    !["/login", "/myaccount"].includes(location.pathname) && (
                        <span>
            <Link to="/login" className="hlink">Login</Link>
          </span>
                    )
                )}
            </div>
        );
    };



export default Header;
