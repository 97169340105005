import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import "./Login.css";
const AuthPage = () => {
    //check if teh cookie already has value if tur redirect to my account
        if (Cookies.get('jwtToken')) {
            window.location.replace("/myaccount");
        }

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const handleAuth = async () => {
        try {
            if (isLogin) {
                // Login
                const response = await axios.post('https://alexer.dev/api/user/login', { email, password });
                Cookies.set('jwtToken', response.data.token, { expires: 7 }); // 'expires' sets the cookie expiration in days
                window.location.replace("/myaccount");

            } else {
                // Signup
                const response = await axios.post('https://alexer.dev/api/user/signup', { name, email, password });
                Cookies.set('jwtToken', response.data.token, { expires: 7 }); // 'expires' sets the cookie expiration in days
                window.location.replace("/myaccount");

            }
        } catch (error) {
            console.error(error);
            if (error.response) {
                // The error is a response from the server
                alert(error.response.data.message);


            } else {
                // The error is not a response from the server
                alert("An error occurred");
            }             }
    };



    const handleTabChange = (value) => {
        setIsLogin(value);
    };

    return (
        <div className="login-form-container">
            <div className="tabs">
                <button
                    className={`tab-button ${isLogin ? 'active' : ''}`}
                    onClick={() => handleTabChange(true)}
                >
                    Login
                </button>
                <button
                    className={`tab-button ${isLogin ? '' : 'active'}`}
                    onClick={() => handleTabChange(false)}
                >
                    Sign Up
                </button>
            </div>
            {!isLogin && (
                <input
                    className="input-field"
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            )}
            <input
                className="input-field"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                className="input-field"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button className="submit-button" onClick={handleAuth}>
                {isLogin ? 'Login' : 'Sign Up'}
            </button>
        </div>
    );

};


export default AuthPage;
