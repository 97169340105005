import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Router, Routes, and Route
import './index.css';
import Chat from './Chat';
import NotFound from './NotFound';
import reportWebVitals from './reportWebVitals';
import Header from './components/header/Header';
import Pay from "./Pay";
import HomePage from "./HomePage";
import Contact from "./Contact";
import AuthPage from "./Login";
import Myaccount from "./Myaccount";
import PaymentCompletion from "./PaymentCompletion";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <div>
        <Router>
            <Header />

            <Routes>
                <Route path ="/" element={<HomePage />}></Route>
                <Route path="/chat" element={<Chat />} />
                <Route path="/pay" element={<Pay />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/myaccount" element={<Myaccount />} />
                <Route path="/completion" element={<PaymentCompletion />} />
                <Route path="/login" element={<AuthPage />} />
                <Route path="1" element={<NotFound />} />
                <Route path="2" element={<NotFound />} />
                <Route path="3" element={<NotFound />} />
                <Route path="4" element={<NotFound />} />
                <Route path="5" element={<NotFound />} />

                <Route path="6" element={<NotFound />} />
                <Route path="7" element={<NotFound />} />
                <Route path="8" element={<NotFound />} />
                <Route path="9" element={<NotFound />} />
                <Route path="10" element={<NotFound />} />
                <Route path="11" element={<NotFound />} />
                <Route path="12" element={<NotFound />} />
                <Route path="13" element={<NotFound />} />




                <Route path="*" element={<NotFound />} />

            </Routes>

        </Router>
    </div>
);
if ("serviceWorker" in navigator) {
  window.addEventListener("load", function() {
    navigator.serviceWorker
      .register("/serviceWorker.js")
      .then(res => console.log("service worker registered"))
      .catch(err => console.log("service worker not registered", err))
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
