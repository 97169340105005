///retreate teh clientsecret and paymentintent from the get params
    import {useLocation} from "react-router-dom";
import React , { useEffect, useState } from "react";
import axios from "axios";
function Capitalize(str){
    return str.charAt(0).toUpperCase() + str.slice(1);
}
function PaymentCompletion() {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const clientSecret = params.get('payment_intent_client_secret');
    const paymentIntent = params.get('payment_intent');
    const [amount, setAmount] = useState(0);
    const [Message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const handlePayment = async () => {
        try {
            const response = await axios.post('https://alexer.dev/api/stripe/completion', {clientSecret: clientSecret, paymentIntent: paymentIntent });
            if (response.data.status === 'succeeded') {
                setMessage("Payment Successful from " + Capitalize(response.data.paymentMethod));
            } else if (response.data.status === 'processing') {
                setMessage("Payment is still processing from " + Capitalize(response.data.paymentMethod));
            }
            setAmount(response.data.amount)
        } catch (error) {
            console.error(error);
            }

    }
    return (
        <div>
            <h1>Payment Completion</h1>
            <button onClick={handlePayment}>Confirm Payment</button>
            <h2>{Message}</h2>}
            {isError && <h2>There was an error confirming your payment.</h2>}
        </div>
    );
}
export default PaymentCompletion;
