import React, { useState } from 'react';
import axios from 'axios';
import "./Contact.css";
function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSubmitting) {
      return; // Prevent double submission
    }

    setIsSubmitting(true);

    axios.post('/api/misc/contact', formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
        .then((response) => {
          if (response.status === 200) {
            alert(response.data);
            console.log('Message sent successfully!');
            setFormData({
              name: '',
              email: '',
              message: '',
            });
          } else {
            console.error('Error sending message.');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
  };

  return (
      <div class="container">
        <h2>Contact Us</h2>
        <form id = "ContactForm" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name">Name:</label>
            <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
            />
          </div>
          <div>
            <label htmlFor="email">Email:</label>
            <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
            />
          </div>
          <div>
            <label htmlFor="message">Message:</label>
            <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
            />
          </div>
          <button type="submit" disabled={isSubmitting}>Submit</button>
        </form>
      </div>
  );
}

export default Contact;
