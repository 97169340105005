// ChatChatChat.js
import React, { Component } from "react";
import "./Chat.css";
import { connect, sendMsg } from "./api/websocket";
import ChatHistory from './components/ChatHistory/ChatHistory';
import ChatInput from './components/ChatInput/ChatInput';

class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chatHistory: []
        }
    }
    componentDidMount() {
        connect((msg) => {
            console.log("New Message")
            this.setState(prevState => ({
                chatHistory: [...this.state.chatHistory, msg]
            }))
            console.log(this.state);
        });
    }
    send(event) {
        if (event.keyCode === 13) {
            const message = event.target.value.trim(); // Remove leading and trailing whitespace
            if (message !== "") { // Check if message is not empty
                sendMsg(message);
                event.target.value = "";
            }
        }
    }


    render() {
        return (
            <div className="Chat">
                <ChatHistory chatHistory={this.state.chatHistory} />
                <ChatInput send={this.send} />
            </div>
        );
    }
}
export default Chat;
