import React from 'react';
import './HomePage.css'; // Import the CSS file for styles
import { Link } from 'react-router-dom'; // Import the Link component from React Router
import axios from 'axios';

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            ip: 'Loading...', // Initial state for IP address
        };
    }

    componentDidMount() {
        this.timerID = setInterval(() => {
            const currentDate = new Date();
            const dateOnly = currentDate.toISOString().split('T')[0]; // Extract the date part
            this.setState({ date: dateOnly });
        }, 1000);

        // Fetch the IP address
        axios.get('https://api.ipify.org?format=json')
            .then(response => {
                this.setState({ ip: response.data.ip });
            })
            .catch(error => {
                console.error('Error fetching IP address:', error);
            });
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    render() {
        return (
            <div className="homepage">

                <main className="main">
                    <section>
                        <h2>Welcome</h2>
                        <h3>The current date is {this.state.date.toLocaleString()}</h3>
                        <h3>Your IP address is: {this.state.ip}</h3>
                    </section>
                    <section>
                        <h2>About Us</h2>
                        <p>
                            Welcome to my website! Where I experiment with random totality irrelevant feature. <br/>



                        </p>
                    </section>
                    <section>
                        <h2>Features</h2>
                        <ul>
                            <li>React-based web application</li>
                            <li>Customizable and modular</li>
                            <li>Easy to use and extend</li>
                            <li>
                                <Link to="/chat">Chat App</Link> {/* Use Link instead of anchor tag */}
                            </li>
                            <li>
                                <Link to="/pay">Payment Form</Link> {/* Use Link instead of anchor tag */}
                            </li>
                            <li>
                                <Link to="/contact">Contact Us</Link> {/* Use Link instead of anchor tag */}
                            </li>
                            <li>More coming soon</li>

                        </ul>
                    </section>
                </main>
            </div>
        );
    }
}

export default HomePage;
