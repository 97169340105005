import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from "react";
import CheckoutForm from './components/Checkout/CheckoutForm';
import axios from 'axios'; // Import Axios for making HTTP requests
import './Pay.css';
import Cookies from "js-cookie";

const stripePromise = loadStripe('pk_test_51KlphpLK5fuqk4wRqfsu5VBKlNxbDYFv5Xi7Ep7ZI0aTf5i6j7rRZKsEZwNqVT5g1txNUrKl7D9uJJoeabMk8w2G00AfabOZ2m');

export default function Pay() {
    const [clientSecret, setClientSecret] = useState(null);
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        // Fetch the clientSecret and amount from your API endpoint (e.g., /stripe/createPayment)
        // Replace with your actual API endpoint for getting payment details
        axios.post('https://alexer.dev/api/stripe/payment', { amount: 30000, token:Cookies.get('jwtToken')})
            .then(response => {
                const data = response.data;
                setClientSecret(data.clientSecret);
                setAmount(data.amount);
                console.log(data.context);
            })
            .catch(error => {
                console.error('Error fetching payment details:', error);
            });
    }, []);
        const options = {
        // passing the client secret obtained from the server
        clientSecret: clientSecret
    };

    if (clientSecret === null) {
        return <div>Loading...</div>;
    }

    return (
        <div className="stripe-container">
            <h1>Please pay {amount / 100}</h1>
            <Elements stripe={stripePromise} options={options}>
                <div className="form-container">
                    <CheckoutForm />
                </div>
            </Elements>
        </div>
    );
}
