import React, { Component } from "react";
import "./Message.scss";

class Message extends Component {
    render() {
        return <div className="Message">{this.props.message}</div>;
    }
}

export default Message;
